<template>
  <div class="lines box-card forum-manage" @scroll="scrollBottom">
    <el-row :gutter="12">
      <el-col
        :span="8"
        v-for="(item, index) in forum.list"
        :key="index"
        v-if="forum.list.length != 0"
      >
        <div class="forum">
          <el-card shadow="always" class="forum-card">
            <div class="bottom-document" @click="item.isShow = !item.isShow">
              <div class="bottom-title">
                <span class="title"> {{ item.fundName }} </span>
                <span
                  class="icon-pack-up"
                  :class="{ 'bottom-icon': item.isShow }"
                >
                  <el-icon size="16px" color="#67696D"><ArrowRight /></el-icon>
                </span>
              </div>
            </div>
            <el-carousel
              indicator-position="outside"
              :autoplay="false"
              arrow="hover"
              @click="toInfo(item.fundId)"
            >
              <el-carousel-item v-if="item.totalNet">
                <TotalNetValueChart :totalNetWorth="item.totalNetWorth" />
              </el-carousel-item>
              <el-carousel-item
                v-if="item.videoUrl != '' && item.videoUrl != null"
                style="padding-top: 0px"
              >
                <ForumVideo :videoUrl="item.videoUrl" />
              </el-carousel-item>
              <el-carousel-item v-if="item.unitNet">
                <UnitNetValueChart :unitNetWorth="item.unitNetWorth" />
              </el-carousel-item>
            </el-carousel>
            <div></div>
          </el-card>
          <div
            :class="{ 'show-forum': item.isShow, 'forum-bottom': !item.isShow }"
          >
            <div
              class="document-content box-card"
              v-html="item.info"
              style="white-space: pre-wrap"
            ></div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-empty v-if="forum.list.length == 0"></el-empty>
  </div>
</template>
<script setup>
import ForumVideo from "./components/ForumVideo.vue";
import UnitNetValueChart from "./components/UnitNetValueChart.vue";
import TotalNetValueChart from "./components/TotalNetValueChart.vue";
import { ref, reactive, onMounted, onUnmounted } from "vue";
import { ArrowRight } from "@element-plus/icons-vue";
import { fundNetWorthPicture } from "./forumApi.js";
import { useRoute, useRouter } from "vue-router";

let documentText = ref(
  "<h1>测试01</h1><p>  Consistent with real life: in li></p><p>  Consistent with real life: in line with the process and logicof real life, and comply with languages and habits that the users are used to;</p>"
);
let activeNames = ref("");
const forum = reactive({
  list: [
    // {
    //   title: "测试1",
    //   activeNames: "",
    //   documentText: documentText,
    //   video: true,
    //   totalNet: true,
    //   unitNet: true,
    //   isShow: false,
    // },
  ],
});
let route = useRoute();
let router = useRouter();
onMounted(() => {
  window.addEventListener("scroll", scrollBottom, true);
  getList();
});

const paramsForm = reactive({
  pageIndex: 1,
  noMore: false,
  page: 1,
  pageSize: 16,
  total: null,
  noMore: false,
});
const scrollBottom = (e) => {
  const { scrollTop, scrollHeight, clientHeight } = e.srcElement;
  // 滚动距离顶部高度 + 可视区域高度 >= 滚动内容高度，说明滚动到底
  if (scrollTop + clientHeight >= scrollHeight - 100) {
    if (paramsForm.noMore || paramsForm.total === forum.list.length) {
      return;
    } else {
      paramsForm.pageIndex++;
      getMoreData();
    }
  }
};
onUnmounted(() => {
  window.removeEventListener("scroll", scrollBottom, true);
});
const toInfo = (fundId) => {
  router.push({
    path: "/details",
    query: { fundId: fundId },
  });
};
const getList = () => {
  let parm = {
    page: paramsForm.page,
    pageSize: paramsForm.pageSize,
  };
  fundNetWorthPicture(parm).then((res) => {
    forum.list = res.data.data.list;
    paramsForm.total = res.data.data.total;
    paramsForm.noMore = paramsForm.total.length < 16 ? true : false;
  });
};

const getMoreData = () => {
  paramsForm.page = paramsForm.page + 1;
  let parm = {
    page: paramsForm.page,
    pageSize: paramsForm.pageSize,
  };
  fundNetWorthPicture(parm).then((res) => {
    if (forum.list.length < res.data.data.total) {
      paramsForm.noMore = false;
      res.data.data.list.forEach((item) => {
        forum.list.push(item);
      });
    } else {
      paramsForm.noMore = true;
    }

    paramsForm.total = res.data.data.total;
  });
  // const { data: res } = await axios.post(`xxxx`, {
  //   pageSize: 4,
  //   pageIndex: this.pageIndex- 1,
  // })
  // if (forum.list.length < 40) {
  //   let arr = [1, 2, 3, 4, 5, 6];
  //   arr.forEach(() => {
  //     forum.list.push(forum.list[0]);
  //   });
  //   console.log("99999")
  // }
  // if (res.code === 200) {
  //   // 数组有值说明还有数据
  //   if（res.data.length）{
  //      this.pageIndex++
  //      this.data = [...this.data,...res.data] // 合并数据
  //   }
  //   else {
  //     this.noMore = true // 用一个变量来记录没有更多数据了
  //   }
  // }
};
</script>
<style lang="less" scoped>
:deep(.el-carousel__item) {
  background: linear-gradient(180deg, #eef0f4 0%, rgba(248, 248, 248, 0) 100%);
  padding-top: 20px;
  border-radius: 6px;
}
:deep(.el-carousel--horizontal) {
  margin-top: -30px;
}
.forum-manage {
  background-color: #fff;
  border-radius: 6px;
  max-height: calc(100vh - 120px);
  box-sizing: border-box;
}
:deep(.el-carousel__indicator--horizontal) {
  width: 10px;
}
:deep(.el-carousel__indicators--outside) {
  margin-top: 30px;
}
:deep(.el-carousel__indicators--outside button) {
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  border-radius: 4px;
}
:deep(.el-carousel__indicators--outside .is-active button) {
  width: 10px;
  height: 10px;
  background: #3b6efb;
  border-radius: 5px;
}
:deep(.el-card.is-always-shadow) {
  background: #ffffff;
  box-shadow: 0px 6px 12px 0px rgba(148, 159, 206, 0.18);
  border-radius: 6px;
  border: 1px solid #c8cbd6;
}
.box-card::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 7px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box-card::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3.5px;
  -webkit-box-shadow: inset 0 0 7px #ccc;
  background: #ccc;
}
.box-card::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 2px #ccc;
  border-radius: 3.5px;
  background: #ffffff;
}
.lines {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.document-content {
  padding: 20px;
  word-wrap: break-word;
  word-break: break-all;
  height: 410px;
  overflow-y: auto;
}
.forum {
  padding: 30px 20px;
  box-sizing: border-box;
  position: relative;
  height: 510px;

  .forum-bottom {
    position: absolute;
    top: 90px;
    left: 20px;
    background-color: #fff;
    z-index: 2;
    overflow: hidden;
    width: calc(100% - 44px);
    padding-bottom: 0px;
    transition: height 0.3s;
    height: 0px;
    // border: 1px solid #e4e7ed;
  }
  .show-forum {
    display: block;
    overflow: hidden;
    width: calc(100% - 42px);
    position: absolute;
    top: 90px;
    left: 20px;
    background-color: #fff;
    border: 1px solid #e4e7ed;
    z-index: 2;
    height: 80%;
    transition: height 0.3s;
  }
}
.bottom-document {
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #03032c;
  line-height: 24px;
  margin-bottom: 49px;
}
.bottom-title {
  cursor: pointer;
  display: flex;
  transition: transform 0.3s;
  justify-content: space-between;
  .title {
    display: inline-block;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon-pack-up {
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .bottom-icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: transform 0.3s;
  }
}
.document-text {
  position: absolute;
  top: 50px;
  left: 0px;
}
.time {
  font-size: 12px;
  color: #999;
}
.forum-card {
  margin-bottom: 30px;
  height: 100%;
}
.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.el-carousel__item h3 {
  display: flex;
  color: #fff;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item {
  background-color: #fff;
}
</style>
