<template>
  <div ref="unitNetValue" class="unit-net-value"></div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
var option;
const props = defineProps({
	totalNetWorth: {
		date:[],
		value:[]
	}
})
option = {
  title: {
    left:'center',
    text: "总净值图",
  },
  xAxis: {
    type: "category",
    data: props.totalNetWorth.date,
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data:  props.totalNetWorth.value,
      type: "line",
    },
  ],
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
},
};
let unitNetValue = ref();
onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      let psgTimeCharts = echarts.init(unitNetValue.value);
      //传入一个配置项
      psgTimeCharts.setOption(option);
    }, 100);
  });
});
// 传递一个dom元素
</script>
<style lang="less" scope>
//   .unit-net-value {
//     width: 100%;
//     height: 100%;
//     padding-left:5px;
//     display: block;
//   }
</style>
