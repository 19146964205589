import axios from '@/axios/globalaxios'

export const listDiscussionArea = data =>{
    return axios({
        url: "/tradelab/openFund/listDiscussionArea",
        method: 'post',
        data:data
    })
}

export const listTlq = data =>{
    return axios({
        url: "/tradelab/openFund/listTlq",
        method: 'post',
        data:data
    })
}

export const getNetWorthData = data =>{
    return axios({
        url: "/tradelab/openFund/getNetWorthData",
        method: 'post',
        data:data
    })
}

export const insertDiscussionArea = data =>{
    return axios({
        url: "/tradelab/openFund/insertDiscussionArea",
        method: 'post',
        data:data
    })
}

export const fundNetWorthPicture = data =>{
    return axios({
        url: "/tradelab/openFund/fundNetWorthPicture",
        method: 'post',
        data:data
    })
}
